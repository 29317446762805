import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_COURSE_BY_ID } from '../../graphql/queries';
import { useParams } from 'react-router-dom';
import { Skeleton, List, Alert } from 'antd';

const CourseModules = () => {
  const { id } = useParams(); // Получаем ID курса из параметров маршрута

  // Исправляем переменную на id
  const { data, loading, error } = useQuery(GET_COURSE_BY_ID, {
    variables: { id }, // Передаем id вместо courseId
  });

  if (loading) return <Skeleton active />;

  if (error) {
    return <Alert message={`Error: ${error.message}`} type="error" showIcon />;
  }

  if (!data || !data.course) {
    return <p style={{ color: 'red' }}>Course not found</p>;
  }

  const { course } = data;

  return (
    <div style={{ padding: '20px' }}>
      <h2>Модули курса: {course.name}</h2>
      <List
        bordered
        dataSource={course.modules}
        renderItem={(module) => (
          <List.Item>
            <List.Item.Meta
              title={module.name}
              description={module.description}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default CourseModules;