import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Typography, DatePicker, Button, ConfigProvider } from 'antd';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import ruRU from 'antd/lib/locale/ru_RU'; // Импортируем русскую локаль

const { Text } = Typography;
const { RangePicker } = DatePicker;

// Запрос на получение результатов тестов
const GET_TEST_RESULTS = gql`
  query GetTestResults {
    getTestResults {
      id
      score
      completedAt
      userId
      testId
    }
  }
`;

// Запрос на получение пользователей
const GET_USERS = gql`
  query GetUsers {
    allUsers {
      id
      name
    }
  }
`;

// Запрос на получение тестов
const GET_TESTS = gql`
  query GetTests {
    allTests {
      id
      name
    }
  }
`;

const TestResults = () => {
  const { data: testResultsData, loading: loadingTestResults, error: errorTestResults } = useQuery(GET_TEST_RESULTS);
  const { data: usersData, loading: loadingUsers, error: errorUsers } = useQuery(GET_USERS);
  const { data: testsData, loading: loadingTests, error: errorTests } = useQuery(GET_TESTS);
  const [groupedResults, setGroupedResults] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 50, current: 1 });
  const [dateRange, setDateRange] = useState([]);

  // Группируем данные по пользователю и тесту
  useEffect(() => {
    if (testResultsData?.getTestResults && usersData?.allUsers && testsData?.allTests) {
      const results = testResultsData.getTestResults.map((result) => {
        const user = usersData.allUsers.find((user) => user.id === result.userId);
        const test = testsData.allTests.find((test) => test.id === result.testId);

        return {
          key: result.id,
          user: user ? user.name : 'Unknown User',
          test: test ? test.name : 'Unknown Test',
          score: result.score,
          completedAt: result.completedAt ? new Date(Number(result.completedAt)) : null,
        };
      });

      // Группируем результаты по пользователю и тесту
      const grouped = results.reduce((acc, result) => {
        const key = `${result.user}-${result.test}`;
        if (!acc[key]) {
          acc[key] = {
            key,
            user: result.user,
            test: result.test,
            attempts: [],
          };
        }
        acc[key].attempts.push(result);
        return acc;
      }, {});

      // Преобразуем объект в массив для таблицы
      setGroupedResults(Object.values(grouped));
    }
  }, [testResultsData, usersData, testsData]);

  // Обработчик изменения пагинации
  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  // Обработчик изменения диапазона дат
  const handleDateRangeChange = (dates) => {
    console.log('Selected dates:', dates); // Логируем выбранные даты
    setDateRange(dates);
  };

  // Фильтрация данных по выбранному диапазону дат
  const filteredResults = groupedResults
    .map((group) => ({
      ...group,
      attempts: group.attempts.filter((attempt) => {
        if (dateRange.length === 0) return true; // Если диапазон не выбран, показываем все данные
        const attemptDate = new Date(attempt.completedAt);
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);
        endDate.setHours(23, 59, 59, 999); // Устанавливаем конец дня
        return attemptDate >= startDate && attemptDate <= endDate;
      }),
    }))
    .filter((group) => {
      // Если фильтр по датам указан, удаляем пользователей с нулевым количеством попыток
      if (dateRange.length > 0) {
        return group.attempts.length > 0;
      }
      // Если фильтр не указан, выводим всех пользователей
      return true;
    });

  // Проверяем, есть ли ошибка или данные загружаются
  if (loadingTestResults || loadingUsers || loadingTests) return <Spin />;
  if (errorTestResults) return <Alert message={`Error loading test results: ${errorTestResults.message}`} type="error" />;
  if (errorUsers) return <Alert message={`Error loading users: ${errorUsers.message}`} type="error" />;
  if (errorTests) return <Alert message={`Error loading tests: ${errorTests.message}`} type="error" />;

  // Описание колонок для таблицы
  const columns = [
    {
      title: 'Студент',
      dataIndex: 'user',
      key: 'user',
      render: (text) => <Text strong>{text}</Text>,
    },
    {
      title: 'Тест',
      dataIndex: 'test',
      key: 'test',
      render: (text) => <Text type="secondary">{text}</Text>,
    },
    {
      title: 'Количество попыток',
      dataIndex: 'attempts',
      key: 'attempts',
      render: (attempts) => (
        <Text style={{ color: attempts.length > 0 ? '#1890ff' : '#ff4d4f' }}>
          {attempts.length}
        </Text>
      ),
    },
  ];

  // Настройка разворачиваемых строк
  const expandable = {
    expandedRowRender: (record) => (
      <Table
        columns={[
          {
            title: 'Результат',
            dataIndex: 'score',
            key: 'score',
            render: (score) => (
              <Text style={{ color: score >= 50 ? '#52c41a' : '#ff4d4f' }}>
                {score}%
              </Text>
            ),
          },
          {
            title: 'Дата прохождения',
            dataIndex: 'completedAt',
            key: 'completedAt',
            render: (date) => <Text type="secondary">{date ? date.toLocaleDateString() : 'Invalid Date'}</Text>,
          },
        ]}
        dataSource={record.attempts}
        pagination={false}
        rowKey="key"
        size="small"
        bordered
      />
    ),
    rowExpandable: (record) => record.attempts.length > 0,
    expandIcon: ({ expanded, onExpand, record }) =>
      record.attempts.length > 0 ? (
        expanded ? (
          <UpOutlined style={{ color: '#1890ff', cursor: 'pointer' }} onClick={(e) => onExpand(record, e)} />
        ) : (
          <DownOutlined style={{ color: '#1890ff', cursor: 'pointer' }} onClick={(e) => onExpand(record, e)} />
        )
      ) : null,
  };

  return (
    <ConfigProvider locale={ruRU}> {/* Применяем русскую локаль */}
      <div style={{ 
        padding: '24px',
        background: '#ffffff', 
        borderRadius: '8px', 
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        margin: '24px auto 0', // Добавляем отступ сверху
        maxWidth: '1200px',
        width: '100%',
      }}>
        <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center', gap: '8px' }}>
          <RangePicker onChange={handleDateRangeChange} />
          <Button 
            style={{ marginLeft: '8px' }} 
            onClick={() => setDateRange([])}
          >
            Сбросить
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={filteredResults}
          expandable={expandable}
          rowKey="key"
          bordered
          size="middle"
          pagination={{
            ...pagination,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: (total, range) => `${range[0]}-${range[1]} из ${total} записей`,
          }}
          onChange={handleTableChange}
        />
      </div>
    </ConfigProvider>
  );
};

export default TestResults;