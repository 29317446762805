import React, { useState, useEffect } from 'react';
import { Layout, Typography } from 'antd';
import { AppHeader, Sidebar } from './dashboard';
import { Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const { Title } = Typography;

const Dashboard = () => {
  const [selectedKey, setSelectedKey] = useState('1');
  const [collapsed, setCollapsed] = useState(false);
  const [userRole, setUserRole] = useState(null); // Состояние для роли пользователя
  const [userId, setUserId] = useState(null); // Состояние для userId

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.role.toUpperCase()); // Приводим роль к верхнему регистру
        setUserId(decodedToken.id); // Извлекаем userId из токена
      } catch (error) {
        console.error("Ошибка при декодировании токена:", error);
      }
    }
  }, []);

  const handleCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh', background: '#ffffff' }}>
      <Sidebar
        setSelectedKey={setSelectedKey}
        collapsed={collapsed}
        onCollapse={handleCollapse}
        userRole={userRole} // Передаем роль в Sidebar
        userId={userId} // Передаем userId в Sidebar
      />
      <Layout>
        <AppHeader />
        <Outlet context={{ userRole, userId }} /> {/* Передаем пропсы через Outlet */}
      </Layout>
    </Layout>
  );
};

export default Dashboard;