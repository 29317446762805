import React, { useState } from 'react';
import { Table, Spin, Modal, Form, Input, Button, message } from 'antd';
import { gql, useQuery, useMutation } from '@apollo/client';

// GraphQL-запрос для получения списка пользователей
const GET_USERS = gql`
  query {
    allUsers {
      id
      name
      email
      roles {
        id
        name
      }
    }
  }
`;

// GraphQL-мутация для обновления пользователя
const UPDATE_USER = gql`
  mutation UpdateUser(
    $userId: String!  # Исправлено на String!
    $name: String
    $email: String
    $vkAccessToken: String
    $telegramBotToken: String
    $telegramChatId: String
  ) {
    updateUser(
      userId: $userId
      name: $name
      email: $email
      vkAccessToken: $vkAccessToken
      telegramBotToken: $telegramBotToken
      telegramChatId: $telegramChatId
    ) {
      id
      name
      email
      vkAccessToken
      telegramBotToken
      telegramChatId
    }
  }
`;

// GraphQL-мутация для удаления пользователя
const DELETE_USER = gql`
  mutation DeleteUser(
    $userId: String!  # Исправлено на String!
  ) {
    deleteUser(userId: $userId) {
      id
      name
      email
    }
  }
`;

const UserList = () => {
  const { loading, error, data } = useQuery(GET_USERS);
  const [updateUser] = useMutation(UPDATE_USER);
  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_USERS }], // Обновляем список пользователей после удаления
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [form] = Form.useForm();

  if (loading) return <Spin />;
  if (error) {
    console.error(error);
    return <p>Ошибка: {error.message}</p>;
  }

  const users = data?.allUsers || [];

  const handleEdit = (user) => {
    setSelectedUser(user);
    form.setFieldsValue({
      name: user.name,
      email: user.email,
    });
    setIsModalVisible(true);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      await updateUser({
        variables: {
          userId: selectedUser.id.toString(), // Убедитесь, что userId передаётся как строка
          ...values,
        },
      });
      message.success('Пользователь успешно обновлен');
      setIsModalVisible(false);
    } catch (err) {
      console.error('Ошибка при обновлении пользователя:', err);
      message.error('Ошибка при обновлении пользователя');
    }
  };

  const handleDelete = async (userId) => {
    try {
      await deleteUser({ variables: { userId } });
      message.success('Пользователь успешно удалён');
    } catch (err) {
      console.error('Ошибка при удалении пользователя:', err);
      message.error('Ошибка при удалении пользователя');
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Роли',
      dataIndex: 'roles',
      key: 'roles',
      render: roles => roles ? roles.map(role => role.name).join(', ') : 'Нет ролей',
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>
            Редактировать
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>
            Удалить
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table dataSource={users} columns={columns} rowKey={record => record.id} />
      <Modal
        title="Редактировать пользователя"
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Имя" rules={[{ required: true, message: 'Введите имя' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Введите email' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UserList;