import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_MODULE_TO_COURSE } from '../../graphql/mutations';
import { useParams, useNavigate } from 'react-router-dom'; // Импортируем useParams и useNavigate
import { Form, Input, Button, notification } from 'antd';

const { TextArea } = Input;

const CreateModule = () => {
  const { id: courseId } = useParams(); // Получаем id курса из маршрута
  const navigate = useNavigate(); // Для редиректа
  const [form] = Form.useForm(); // Используем Ant Design Form
  const [addModule] = useMutation(ADD_MODULE_TO_COURSE);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields(); // Валидация формы
      const { name, description, order } = values;

      await addModule({
        variables: { id: courseId, name, description, order }, // Используем id вместо courseId
      });

      // Уведомление об успешном добавлении
      notification.success({
        message: 'Успешно',
        description: 'Модуль успешно добавлен!',
      });

      // Редирект обратно на страницу курса
      navigate(`/dashboard/courses/${courseId}`);
    } catch (error) {
      console.error(error);

      // Уведомление об ошибке
      notification.error({
        message: 'Ошибка',
        description: 'Произошла ошибка при добавлении модуля.',
      });
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h3>Создать новый модуль для курса {courseId}</h3>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit} // Обработчик отправки формы
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[{ required: true, message: 'Пожалуйста, введите название модуля!' }]}
        >
          <Input
            placeholder="Введите название модуля"
            onChange={(e) => form.setFieldsValue({ name: e.target.value })}
          />
        </Form.Item>

        <Form.Item
          label="Описание"
          name="description"
        >
          <TextArea
            rows={4}
            placeholder="Введите описание модуля"
            onChange={(e) => form.setFieldsValue({ description: e.target.value })}
          />
        </Form.Item>

        <Form.Item
          label="Порядковый номер"
          name="order"
          initialValue={1}
          rules={[{ required: true, message: 'Пожалуйста, введите порядковый номер!' }]}
        >
          <Input
            type="number"
            placeholder="Введите порядковый номер"
            onChange={(e) => form.setFieldsValue({ order: parseInt(e.target.value) })}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать модуль
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateModule;