import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MY_COURSES } from '../../graphql/queries';
import { Layout, Typography, Card, Spin, Collapse, Button } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

const { Title } = Typography;
const { Panel } = Collapse;

const MyCourses = () => {
  const { userId } = useParams();  // Извлекаем userId из параметров URL
  const [courses, setCourses] = useState([]);

  const { loading, error, data } = useQuery(GET_MY_COURSES, {
    variables: { userId },
  });

  useEffect(() => {
    if (data) {
      setCourses(data.courses || []);
    }
  }, [data]);

  if (loading) return <Spin size="large" />;
  if (error) return <p>Ошибка: {error.message}</p>;

  return (
    <Layout style={{ padding: '20px' }}>
      <Title level={2}>Мои курсы</Title>
      {courses.length === 0 ? (
        <p>У вас пока нет назначенных курсов.</p>
      ) : (
        courses.map((course) => (
          <Card key={course.id} title={course.name} style={{ marginBottom: '20px' }}>
            <p>{course.description}</p>

            {course.modules && course.modules.length > 0 ? (
              <Collapse accordion>
                {course.modules.map((module) => (
                  <Panel header={module.name} key={module.id}>
                    <p>{module.description}</p>

                    {module.tests && module.tests.length > 0 ? (
                      <div>
                        <h4>Доступные тесты:</h4>
                        {module.tests.map((test) => (
                          <Card
                            key={test.id}
                            title={test.name}
                            style={{ marginBottom: '10px' }}
                            extra={
                              <>
                                <Link to={`test/${test.id}`}>
                                  <Button type="primary">Пройти тест</Button>
                                </Link>
                                <Link to={`test-results/${test.id}`} state={{ testId: test.id, userId: userId }}>
                                  <Button style={{ marginLeft: '10px' }}>Посмотреть результаты</Button>
                                </Link>
                              </>
                            }
                          >
                            <p>Попытки: {test.attempts}</p>
                            <p>
                              Шкала оценок: {test.gradingScale.minScore} - {test.gradingScale.maxScore}
                            </p>
                          </Card>
                        ))}
                      </div>
                    ) : (
                      <p>Тестов в этом модуле пока нет.</p>
                    )}
                  </Panel>
                ))}
              </Collapse>
            ) : (
              <p>Модулей в этом курсе пока нет.</p>
            )}
          </Card>
        ))
      )}
      <Outlet /> {/* Здесь будут отображаться вложенные маршруты */}
    </Layout>
  );
};

export default MyCourses;