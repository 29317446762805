import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_COURSE } from '../../graphql/mutations';
import { GET_USERS_BY_ROLES } from '../../graphql/queries';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Input, Button, notification, Select, Spin } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const CreateCourse = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { courseId, courseName, courseDescription } = location.state || {};

  const [form] = Form.useForm();
  const [createCourse] = useMutation(CREATE_COURSE);

  // Запрос для получения пользователей с ролями Course Manager или System Administrator
  const { data, loading, error } = useQuery(GET_USERS_BY_ROLES, {
    variables: { roles: ['COURSE MANAGER', 'SYSTEM ADMINISTRATOR'] },
  });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { name, description, teacherId } = values;

      await createCourse({
        variables: { name, description, teacherId },
      });

      notification.success({
        message: 'Успешно',
        description: 'Курс успешно создан!',
      });

      navigate('/dashboard/courses');
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Ошибка',
        description: 'Произошла ошибка при создании курса.',
      });
    }
  };

  // Если данные загружаются, показываем спиннер
  if (loading) return <Spin size="large" />;

  // Если произошла ошибка, показываем сообщение
  if (error) {
    return <p>Ошибка при загрузке данных: {error.message}</p>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <h2>Создать новый курс</h2>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Название курса"
          name="name"
          initialValue={courseName}
          rules={[{ required: true, message: 'Пожалуйста, введите название курса!' }]}
        >
          <Input placeholder="Введите название курса" />
        </Form.Item>

        <Form.Item
          label="Описание курса"
          name="description"
          initialValue={courseDescription}
          rules={[{ required: true, message: 'Пожалуйста, введите описание курса!' }]}
        >
          <TextArea rows={4} placeholder="Введите описание курса" />
        </Form.Item>

        <Form.Item
          label="Преподаватель"
          name="teacherId"
          rules={[{ required: true, message: 'Пожалуйста, выберите преподавателя!' }]}
        >
          <Select placeholder="Выберите преподавателя" loading={loading}>
            {data?.usersByRoles.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.name} ({user.email})
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать курс
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateCourse;