import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_TEST } from '../../graphql/queries';
import { SUBMIT_TEST_RESULT } from '../../graphql/mutations';
import { Layout, Typography, Card, Button, Spin, message } from 'antd';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';

const { Title } = Typography;

const TestPage = () => {
  const { testId } = useParams();
  const navigate = useNavigate();
  const { userId } = useOutletContext();
  const [answers, setAnswers] = useState({});

  const { loading, error, data } = useQuery(GET_TEST, {
    variables: { id: testId },
  });

  const [submitTestResult] = useMutation(SUBMIT_TEST_RESULT, {
    onCompleted: (data) => {
      message.success('Тест успешно завершен!');
      console.log('Отправленные данные: ', data.submitTestResult);
      navigate(`test-results/${data.submitTestResult.id}`);
    },
    onError: (error) => {
      message.error(`Ошибка: ${error.message}`);
    },
  });

  const handleAnswerChange = (questionId, answer) => {
    setAnswers({
      ...answers,
      [questionId]: answer,
    });
  };

  const handleSubmit = () => {
    // Преобразуем данные ответов, всегда делая options массивом
    const formattedAnswers = Object.keys(answers).map((questionId) => {
      const question = test.questions.find((q) => q.id === questionId);

      // Гарантируем, что options всегда будет массивом
      const options = Array.isArray(answers[questionId])
        ? answers[questionId]
        : [answers[questionId]]; // если одиночный выбор, оборачиваем в массив

      return {
        id: questionId,
        text: question.text,
        options, // теперь options - всегда массив
        type: question.type,
      };
    });

    console.log('Форматированные данные для отправки:', formattedAnswers);

    if (!userId) {
      message.error('Пользователь не найден. Пожалуйста, войдите в систему.');
      return;
    }

    submitTestResult({
      variables: {
        testId,
        userId,
        answers: formattedAnswers,
      },
    });
  };

  if (loading) return <Spin size="large" />;
  if (error) return <p>Ошибка: {error.message}</p>;

  const { test } = data;

  return (
    <Layout style={{ padding: '20px' }}>
      <Title level={2}>{test.name}</Title>
      {test.questions.map((question) => (
        <Card
          key={question.id}
          title={
            <Typography.Paragraph
              ellipsis={{ rows: 3, expandable: true, symbol: 'ещё' }}
              style={{ marginBottom: 0 }}
            >
              {question.text}
            </Typography.Paragraph>
          }
          style={{ marginBottom: '20px' }}
        >
          {question.options.map((option, index) => (
            <div key={index}>
              <input
                type={question.type === 'SINGLE' ? 'radio' : 'checkbox'}
                name={question.id}
                value={option}
                onChange={() => handleAnswerChange(question.id, option)}
              />
              {option}
            </div>
          ))}
        </Card>
      ))}
      <Button type="primary" onClick={handleSubmit}>
        Завершить тест
      </Button>
    </Layout>
  );
};

export default TestPage;