import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Table, Select, Button, Spin, notification } from 'antd';

// Запрос для получения пользователей
const GET_USERS = gql`
  query {
    allUsers {
      id
      name
      email
      roles {
        id
        name
      }
      region {
        id
        name
      }
    }
  }
`;

// Запрос для получения ролей
const GET_ROLES = gql`
  query {
    roles {
      id
      name
    }
  }
`;

// Запрос для получения регионов
const GET_REGIONS = gql`
  query {
    regions {
      id
      name
    }
  }
`;

// Мутация для обновления роли пользователя
const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($userId: ID!, $roleId: ID!) {
    updateUserRole(userId: $userId, roleId: $roleId) {
      id
      roles {
        id
        name
      }
    }
  }
`;

// Мутация для назначения региона пользователю
const ASSIGN_REGION_TO_USER = gql`
  mutation AssignRegionToUser($userId: String!, $regionId: String!) {
    assignRegionToUser(userId: $userId, regionId: $regionId) {
      id
      region {
        id
        name
      }
    }
  }
`;

const EditUserRole = () => {
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_USERS);
  const { loading: rolesLoading, error: rolesError, data: rolesData } = useQuery(GET_ROLES);
  const { loading: regionsLoading, error: regionsError, data: regionsData } = useQuery(GET_REGIONS);

  const [updateUserRole] = useMutation(UPDATE_USER_ROLE);
  const [assignRegionToUser] = useMutation(ASSIGN_REGION_TO_USER);

  const [selectedRoles, setSelectedRoles] = useState({});
  const [selectedRegions, setSelectedRegions] = useState({});

  // Синхронизация состояния selectedRoles и selectedRegions с изначальными данными пользователей
  useEffect(() => {
    if (usersData && usersData.allUsers) {
      const initialRoles = usersData.allUsers.reduce((acc, user) => {
        acc[user.id] = user.roles[0]?.id || null;
        return acc;
      }, {});
      const initialRegions = usersData.allUsers.reduce((acc, user) => {
        acc[user.id] = user.region?.id || null; // Если регион отсутствует, используем null
        return acc;
      }, {});
      setSelectedRoles(initialRoles);
      setSelectedRegions(initialRegions);
    }
  }, [usersData]);

  if (usersLoading || rolesLoading || regionsLoading) return <Spin />;
  if (usersError) return <p>Ошибка пользователей: {usersError.message}</p>;
  if (rolesError) return <p>Ошибка ролей: {usersError.message}</p>;
  if (regionsError) return <p>Ошибка регионов: {regionsError.message}</p>;

  const handleRoleChange = (userId, roleId) => {
    setSelectedRoles((prev) => ({
      ...prev,
      [userId]: roleId,
    }));
  };

  const handleRegionChange = (userId, regionId) => {
    setSelectedRegions((prev) => ({
      ...prev,
      [userId]: regionId,
    }));
  };

  const handleUpdateRole = async (userId) => {
    const roleId = selectedRoles[userId];
    if (!roleId) {
      notification.error({ message: 'Выберите роль для обновления' });
      return;
    }

    try {
      await updateUserRole({ variables: { userId, roleId } });
      notification.success({ message: 'Роль успешно обновлена!' });
    } catch (error) {
      notification.error({ message: 'Ошибка при обновлении роли.', description: error.message });
    }
  };

  const handleAssignRegion = async (userId) => {
    const regionId = selectedRegions[userId];
    if (!regionId) {
      notification.error({ message: 'Выберите регион для назначения' });
      return;
    }

    try {
      await assignRegionToUser({ variables: { userId, regionId } });
      notification.success({ message: 'Регион успешно назначен!' });
    } catch (error) {
      notification.error({ message: 'Ошибка при назначении региона.', description: error.message });
    }
  };

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Роль',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles, record) => (
        <Select
          value={selectedRoles[record.id] || null}
          onChange={(value) => handleRoleChange(record.id, value)}
          style={{ width: 200 }}
        >
          {rolesData.roles.map((role) => (
            <Select.Option key={role.id} value={role.id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Регион',
      dataIndex: 'region',
      key: 'region',
      render: (region, record) => (
        <Select
          value={selectedRegions[record.id] || null} // Устанавливаем текущий регион пользователя
          onChange={(value) => handleRegionChange(record.id, value)}
          style={{ width: 200 }}
        >
          {regionsData.regions.map((region) => (
            <Select.Option key={region.id} value={region.id}>
              {region.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text, record) => (
        <>
          <Button onClick={() => handleUpdateRole(record.id)} type="primary" style={{ marginRight: 8 }}>
            Обновить роль
          </Button>
          <Button onClick={() => handleAssignRegion(record.id)} type="primary">
            Назначить регион
          </Button>
        </>
      ),
    },
  ];

  return <Table dataSource={usersData.allUsers} columns={columns} rowKey="id" />;
};

export default EditUserRole;