import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Image, Checkbox, Typography, Space, Row, Col } from 'antd';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Title } = Typography;

const GET_NEWS_BY_ID = gql`
  query getNewsById($id: String!) {
    news(id: $id) {
      id
      title
      lead
      body
      publishDate
      posted
      imageUrl
    }
  }
`;

const UPDATE_NEWS = gql`
  mutation updateNews(
    $id: String!,
    $title: String!,
    $lead: String!,
    $body: String!,
    $publishDate: String!,
    $posted: Boolean!,
    $imageUrl: String
  ) {
    updateNews(
      id: $id,
      title: $title,
      lead: $lead,
      body: $body,
      publishDate: $publishDate,
      posted: $posted,
      imageUrl: $imageUrl
    ) {
      id
    }
  }
`;

const formatDate = (timestamp) => {
  const date = new Date(Number(timestamp));
  const yyyy = date.getFullYear();
  const MM = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const mm = String(date.getMinutes()).padStart(2, '0');
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
};

const EditNews = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_NEWS_BY_ID, { variables: { id } });
  const [updateNews] = useMutation(UPDATE_NEWS);
  const [form] = Form.useForm();
  const [body, setBody] = useState('');

  useEffect(() => {
    if (data?.news) {
      form.setFieldsValue({
        title: data.news.title,
        lead: data.news.lead,
        publishDate: formatDate(data.news.publishDate),
        posted: data.news.posted,
        imageUrl: data.news.imageUrl,
      });
      setBody(data.news.body);
    }
  }, [data, form]);

  const handleSubmit = async (values) => {
    try {
      await updateNews({
        variables: {
          id,
          title: values.title,
          lead: values.lead,
          body,
          publishDate: new Date(values.publishDate).toISOString(),
          posted: values.posted,
          imageUrl: values.imageUrl,
        },
      });
      notification.success({
        message: 'Успешно',
        description: 'Новость успешно обновлена.',
      });
      navigate('/news');
    } catch {
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось обновить новость. Проверьте данные и попробуйте снова.',
      });
    }
  };

  if (loading) return <p>Загрузка...</p>;
  if (error) return <p>Ошибка: {error.message}</p>;

  return (
    <Row justify="center">
      <Col xs={24} sm={20} md={16} lg={12}>
        <div style={{ background: '#fff', padding: '24px', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Title level={3} style={{ textAlign: 'center', marginBottom: '24px' }}>Редактирование новости</Title>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name="title"
              label="Заголовок"
              rules={[{ required: true, message: 'Пожалуйста, введите заголовок!' }]}
            >
              <Input placeholder="Введите заголовок" />
            </Form.Item>
            <Form.Item
              name="lead"
              label="Лид"
              rules={[{ required: true, message: 'Пожалуйста, введите лид!' }]}
            >
              <Input.TextArea rows={3} placeholder="Введите лид" />
            </Form.Item>
            <Form.Item label="Текст" required>
              <ReactQuill
                value={body}
                onChange={setBody}
                placeholder="Введите текст новости"
                style={{ height: '300px', marginBottom: '24px' }}
              />
            </Form.Item>
            <Form.Item
              name="publishDate"
              label="Дата публикации"
              rules={[{ required: true, message: 'Пожалуйста, введите дату публикации!' }]}
            >
              <Input type="datetime-local" />
            </Form.Item>
            <Form.Item name="posted" valuePropName="checked">
              <Checkbox>Опубликовано</Checkbox>
            </Form.Item>
            <Form.Item name="imageUrl" label="URL изображения">
              <Input placeholder="Введите URL изображения (необязательно)" />
            </Form.Item>
            {data?.news?.imageUrl && (
              <Form.Item label="Текущее изображение">
                <Image width={200} src={data.news.imageUrl} style={{ borderRadius: '8px' }} />
              </Form.Item>
            )}
            <Form.Item>
              <Space style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
                <Button onClick={() => navigate('/news')}>Отмена</Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default EditNews;

