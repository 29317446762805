import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_COURSE_BY_ID, GET_ALL_USERS } from '../../graphql/queries';
import { ASSIGN_STUDENT_TO_COURSE, DELETE_COURSE, DELETE_MODULE, UPDATE_COURSE, UPDATE_MODULE } from '../../graphql/mutations';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { Skeleton, Card, Alert, List, Button, Collapse, Modal, Select, Form, Input } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Meta } = Card;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const CourseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(GET_COURSE_BY_ID, { variables: { id } });
  const [isStudentsModalVisible, setIsStudentsModalVisible] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isEditCourseModalVisible, setIsEditCourseModalVisible] = useState(false);
  const [isEditModuleModalVisible, setIsEditModuleModalVisible] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);

  const [form] = Form.useForm();

  const [assignStudentToCourse] = useMutation(ASSIGN_STUDENT_TO_COURSE, {
    update(cache, { data: { assignStudentToCourse } }) {
      const existingCourse = cache.readQuery({
        query: GET_COURSE_BY_ID,
        variables: { id },
      });

      if (existingCourse && existingCourse.course) {
        const updatedCourse = {
          ...existingCourse.course,
          students: [
            ...existingCourse.course.students,
            { __typename: 'User', id: selectedStudent },
          ],
        };

        cache.writeQuery({
          query: GET_COURSE_BY_ID,
          variables: { id },
          data: { course: updatedCourse },
        });
      }
    },
  });

  const [deleteCourse] = useMutation(DELETE_COURSE, {
    onCompleted: () => {
      navigate('/dashboard/courses');
    },
  });

  const [deleteModule] = useMutation(DELETE_MODULE, {
    refetchQueries: [{ query: GET_COURSE_BY_ID, variables: { id } }],
  });

  const [updateCourse] = useMutation(UPDATE_COURSE, {
    refetchQueries: [{ query: GET_COURSE_BY_ID, variables: { id } }],
  });

  const [updateModule] = useMutation(UPDATE_MODULE, {
    refetchQueries: [{ query: GET_COURSE_BY_ID, variables: { id } }],
  });

  const { data: allUsersData, loading: allUsersLoading } = useQuery(GET_ALL_USERS);
  const { userRole } = useOutletContext();
  const allowedRoles = ['COURSE MANAGER', 'SYSTEM ADMINISTRATOR'];

  if (loading) return <Skeleton active />;
  if (error) return <Alert message={`Error: ${error.message}`} type="error" showIcon />;
  if (!data || !data.course) return <p style={{ color: 'red' }}>Course not found</p>;

  const course = data.course || { students: [], modules: [] };

  const handleAddModule = () => {
    navigate(`/dashboard/courses/${id}/create-module`);
  };

  const handleCreateTest = (moduleId) => {
    navigate(`/dashboard/courses/${id}/modules/${moduleId}/create-test`);
  };

  const handleDuplicateCourse = () => {
    navigate('/dashboard/create-course', {
      state: {
        courseId: id,
        courseName: course.name,
        courseDescription: course.description,
      },
    });
  };

  const handleShowStudents = () => {
    setIsStudentsModalVisible(true);
  };

  const handleCloseStudentsModal = () => {
    setIsStudentsModalVisible(false);
  };

  const handleAddStudent = async () => {
    if (selectedStudent) {
      try {
        await assignStudentToCourse({
          variables: {
            courseId: id,
            studentId: selectedStudent,
          },
        });
        alert('Студент успешно добавлен на курс!');
        setSelectedStudent(null);
        setIsStudentsModalVisible(false);
      } catch (error) {
        console.error('Ошибка при добавлении студента:', error);
        alert('Ошибка при добавлении студента на курс.');
      }
    }
  };

  const handleDeleteCourse = async () => {
    try {
      await deleteCourse({ variables: { courseId: id } });
    } catch (error) {
      console.error('Ошибка при удалении курса:', error);
      alert('Ошибка при удалении курса.');
    }
  };

  const handleDeleteModule = async (moduleId) => {
    try {
      await deleteModule({ variables: { moduleId } });
    } catch (error) {
      console.error('Ошибка при удалении модуля:', error);
      alert('Ошибка при удалении модуля.');
    }
  };

  const handleEditCourse = () => {
    form.setFieldsValue({
      name: course.name,
      description: course.description,
    });
    setIsEditCourseModalVisible(true);
  };

  const handleEditModule = (module) => {
    setSelectedModule(module);
    form.setFieldsValue({
      name: module.name,
      description: module.description,
    });
    setIsEditModuleModalVisible(true);
  };

  const handleUpdateCourse = async (values) => {
    try {
      await updateCourse({
        variables: {
          courseId: id,
          name: values.name,
          description: values.description,
        },
      });
      setIsEditCourseModalVisible(false);
    } catch (error) {
      console.error('Ошибка при обновлении курса:', error);
      alert('Ошибка при обновлении курса.');
    }
  };

  const handleUpdateModule = async (values) => {
    try {
      await updateModule({
        variables: {
          moduleId: selectedModule.id,
          name: values.name,
          description: values.description,
        },
      });
      setIsEditModuleModalVisible(false);
    } catch (error) {
      console.error('Ошибка при обновлении модуля:', error);
      alert('Ошибка при обновлении модуля.');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>{course.name}</h2>
      <Card style={{ width: 600 }}>
        <Meta
          title={course.name}
          description={course.description}
        />
      </Card>

      {allowedRoles.includes(userRole) && (
        <Button type="primary" onClick={handleAddModule} style={{ marginTop: '20px' }}>
          Добавить модуль
        </Button>
      )}

      {allowedRoles.includes(userRole) && (
        <Button type="primary" onClick={handleDuplicateCourse} style={{ marginTop: '20px', marginLeft: '10px' }}>
          Дублировать курс
        </Button>
      )}

      {allowedRoles.includes(userRole) && (
        <Button type="primary" onClick={handleEditCourse} style={{ marginTop: '20px', marginLeft: '10px' }}>
          Редактировать курс
        </Button>
      )}

      {allowedRoles.includes(userRole) && (
        <Button type="danger" onClick={handleDeleteCourse} style={{ marginTop: '20px', marginLeft: '10px' }}>
          Удалить курс
        </Button>
      )}

      <Button type="primary" onClick={handleShowStudents} style={{ marginTop: '20px', marginLeft: '10px' }}>
        Студенты
      </Button>

      <h3 style={{ marginTop: '20px' }}>Модули курса</h3>
      {course.modules && course.modules.length > 0 ? (
        <Collapse>
          {course.modules.map((module) => (
            <Panel header={module.name} key={module.id}>
              <p>{module.description}</p>

              <h4>Тесты</h4>
              {module.tests && module.tests.length > 0 ? (
                <List
                  bordered
                  dataSource={module.tests}
                  renderItem={(test) => (
                    <List.Item>
                      <List.Item.Meta
                        title={test.name}
                        description={`Попытки: ${test.attempts}, Шкала оценок: ${test.gradingScale}`}
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <p>Тестов пока нет.</p>
              )}

              {allowedRoles.includes(userRole) && (
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => handleCreateTest(module.id)}
                >
                  Создать тест
                </Button>
              )}

              {allowedRoles.includes(userRole) && (
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => handleEditModule(module)}
                >
                  Редактировать модуль
                </Button>
              )}

              {allowedRoles.includes(userRole) && (
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDeleteModule(module.id)}
                >
                  Удалить модуль
                </Button>
              )}
            </Panel>
          ))}
        </Collapse>
      ) : (
        <p>Модули пока не добавлены.</p>
      )}

      <Modal
        title="Список студентов курса"
        visible={isStudentsModalVisible}
        onCancel={handleCloseStudentsModal}
        footer={[
          <Button key="cancel" onClick={handleCloseStudentsModal}>
            Закрыть
          </Button>,
          <Button
            key="addStudent"
            type="primary"
            onClick={handleAddStudent}
            disabled={!selectedStudent}
          >
            Добавить студента
          </Button>,
        ]}
      >
        {course.students && course.students.length > 0 ? (
          <List
            bordered
            dataSource={course.students}
            renderItem={(student) => (
              <List.Item>
                <List.Item.Meta
                  title={student.name}
                  description={student.email}
                />
              </List.Item>
            )}
          />
        ) : (
          <p>На курс пока не добавлены студенты.</p>
        )}

        <Select
          style={{ width: '100%', marginTop: '20px' }}
          placeholder="Выберите студента для добавления"
          value={selectedStudent}
          onChange={(value) => setSelectedStudent(value)}
          loading={allUsersLoading}
        >
          {allUsersData?.allUsers.map((user) => (
            <Option key={user.id} value={user.id}>
              {user.name} ({user.email})
            </Option>
          ))}
        </Select>
      </Modal>

      <Modal
        title="Редактировать курс"
        visible={isEditCourseModalVisible}
        onCancel={() => setIsEditCourseModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsEditCourseModalVisible(false)}>
            Закрыть
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Сохранить
          </Button>,
        ]}
      >
        <Form form={form} onFinish={handleUpdateCourse}>
          <Form.Item name="name" label="Название курса" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Описание курса">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Редактировать модуль"
        visible={isEditModuleModalVisible}
        onCancel={() => setIsEditModuleModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsEditModuleModalVisible(false)}>
            Закрыть
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Сохранить
          </Button>,
        ]}
      >
        <Form form={form} onFinish={handleUpdateModule}>
          <Form.Item name="name" label="Название модуля" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Описание модуля">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CourseDetails;