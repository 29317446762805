import { gql } from '@apollo/client';

// Мутация на создание курса
export const CREATE_COURSE = gql`
  mutation CreateCourse($name: String!, $description: String, $teacherId: ID!) {
    createCourse(name: $name, description: $description, teacherId: $teacherId) {
      id
      name
      description
    }
  }
`;

// Мутация на добавление модуля в курс
export const ADD_MODULE_TO_COURSE = gql`
    mutation AddModuleToCourse($id: ID!, $name: String!, $order: Int) {
    addModuleToCourse(courseId: $id, name: $name, order: $order) {
        id
        name
        description
        order
    }
    }
`;

export const ASSIGN_STUDENT_TO_COURSE = gql`
  mutation AssignStudentToCourse($courseId: ID!, $studentId: ID!) {
    assignStudentToCourse(courseId: $courseId, studentId: $studentId)
  }
`;

export const ADD_TEST_TO_MODULE = gql`
  mutation AddTestToModule(
    $moduleId: ID!
    $name: String!
    $questions: [QuestionInput!]
    $attempts: Int!
    $gradingScale: String!
  ) {
    addTestToModule(
      moduleId: $moduleId
      name: $name
      questions: $questions
      attempts: $attempts
      gradingScale: $gradingScale
    ) {
      id
      name
      attempts
      gradingScale {
        minScore
        maxScore
      }
      questions {
        id
        text
        options
        correctAnswers
        type
      }
    }
  }
`;


export const SUBMIT_TEST_RESULT = gql`
  mutation SubmitTestResult($testId: ID!, $userId: ID!, $answers: [QuestionInput!]!) {
    submitTestResult(testId: $testId, userId: $userId, answers: $answers) {
      id
      testId
      userId
      score
      completedAt
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation DeleteCourse($courseId: ID!) {
    deleteCourse(courseId: $courseId)
  }
`;

export const UPDATE_COURSE = gql`
  mutation UpdateCourse($courseId: ID!, $name: String, $description: String, $teacherId: ID) {
    updateCourse(courseId: $courseId, name: $name, description: $description, teacherId: $teacherId) {
      id
      name
      description
    }
  }
`;

export const DELETE_MODULE = gql`
  mutation DeleteModule($moduleId: ID!) {
    deleteModule(moduleId: $moduleId)
  }
`;

export const UPDATE_MODULE = gql`
  mutation UpdateModule($moduleId: ID!, $name: String, $description: String, $order: Int) {
    updateModule(moduleId: $moduleId, name: $name, description: $description, order: $order) {
      id
      name
      description
      order
    }
  }
`;