import { gql } from '@apollo/client';

// Запрос на получение всех курсов
export const GET_COURSES = gql`
  query GetCourses {
    courses {
      id
      name
      description
      modules {
        id
        name
        order
      }
    }
  }
`;

export const GET_COURSE_BY_ID = gql`
  query GetCourseById($id: ID!) {
    course(id: $id) {
      id
      name
      description
      modules {
        id
        name
        order
        description
        tests {
          id
          name
          attempts
          gradingScale {
            minScore
            maxScore
          }
          questions {
            id
            text
            options
            correctAnswers
            type
          }
        }
      }
      students {
        id
        name
        email
      }
    }
  }
`;

export const GET_TEST = gql`
  query GetTest($id: ID!) {
    test(id: $id) {
      id
      name
      attempts
      gradingScale {
        minScore
        maxScore
      }
      questions {
        id
        text
        options
        type
      }
    }
  }
`;

export const GET_TEST_RESULT = gql`
  query GetTestResult($testId: ID!, $userId: ID!) {
    getTestResults(testId: $testId, userId: $userId) {
      id
      testId
      userId
      score
      completedAt
    }
  }
`;

// Запрос на получение всех модулей
export const GET_MODULES = gql`
  query GetModules {
    modules {
      id
      name
      order
      course {
        id
        name
      }
    }
  }
`;

// Запрос на получение всех пользователей с ролями
export const GET_ALL_USERS = gql`
  query GetAllUsers {
    allUsers {
      id
      name
      roles {
        name
      }
    }
  }
`;

// Запрос на получение пользователей по ролям
export const GET_USERS_BY_ROLES = gql`
  query GetUsersByRoles($roles: [String!]!) {
    usersByRoles(roles: $roles) {
      id
      name
      email
    }
  }
`;

// Запрос на получение модулей курса
export const GET_COURSE_MODULES = gql`
  query GetCourseModules($courseId: ID!) {
    course(id: $courseId) {
      id
      name
      modules {
        id
        name
        order
      }
    }
  }
`;

// Запрос на получение курсов для студента
export const GET_MY_COURSES = gql`
  query GetMyCourses($userId: ID!) {
    courses(userId: $userId) {
      id
      name
      description
      modules {
        id
        name
        description
        tests {
          id
          name
          attempts
          gradingScale {
            minScore
            maxScore
          }
        }
      }
    }
  }
`;