import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_TEST_RESULT } from '../../graphql/queries';
import { Layout, Typography, Card, Spin } from 'antd';
import { useLocation } from 'react-router-dom';

const { Title } = Typography;

const TestResultsPage = () => {
  // Получаем testId и userId из состояния Link через useLocation
  const location = useLocation();
  const { testId, userId } = location.state || {}; // Защита на случай, если состояние пустое

  console.log("Test ID:", testId);
  console.log("User ID:", userId);

  // Вызываем useQuery до условий
  const { loading, error, data } = useQuery(GET_TEST_RESULT, {
    variables: { testId, userId }, // Передаем testId и userId
  });

  if (loading) return <Spin size="large" />;
  if (error) return <p>Ошибка: {error.message}</p>;

  const testResults = data?.getTestResults || [];

  return (
    <Layout style={{ padding: '20px' }}>
      <Title level={2}>Результаты теста</Title>
      {testResults.length === 0 ? (
        <p>Результаты не найдены.</p>
      ) : (
        testResults.map((result) => (
          <Card key={result.id} style={{ marginBottom: '20px' }}>
            <p>Тест: {result.testId}</p>
            <p>Пользователь: {result.userId}</p>
            <p>Оценка: {result.score}</p>
            <p>Завершен: {result.completedAt}</p>
          </Card>
        ))
      )}
    </Layout>
  );
};

export default TestResultsPage;