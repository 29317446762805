import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_COURSES } from '../../graphql/queries';
import { DELETE_COURSE, UPDATE_COURSE } from '../../graphql/mutations';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Skeleton, Button, Modal, Form, Input, message } from 'antd';
import { LinkOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Meta } = Card;
const { confirm } = Modal;

const CourseList = () => {
  const { data, loading, error, refetch } = useQuery(GET_COURSES);
  const [deleteCourse] = useMutation(DELETE_COURSE);
  const [updateCourse] = useMutation(UPDATE_COURSE);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    console.log('Received data:', data);
    console.log('Error:', error);
  }, [data, error]);

  const handleDelete = (courseId) => {
    confirm({
      title: 'Вы уверены, что хотите удалить этот курс?',
      icon: <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />,
      content: 'Это действие нельзя отменить.',
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отмена',
      onOk: async () => {
        try {
          await deleteCourse({ variables: { courseId } });
          message.success('Курс успешно удален');
          refetch();
        } catch (err) {
          message.error('Ошибка при удалении курса: ' + err.message);
        }
      },
      onCancel() {
        console.log('Удаление отменено');
      },
    });
  };

  const handleEdit = (course) => {
    setSelectedCourse(course);
    form.setFieldsValue({
      name: course.name,
      description: course.description,
    });
    setIsModalVisible(true);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      await updateCourse({
        variables: {
          courseId: selectedCourse.id,
          ...values,
        },
      });
      message.success('Курс успешно обновлен');
      setIsModalVisible(false);
      refetch();
    } catch (err) {
      message.error('Ошибка при обновлении курса: ' + err.message);
    }
  };

  if (loading) return (
    <div style={{ padding: '30px' }}>
      <Skeleton active paragraph={{ rows: 4 }} />
    </div>
  );

  if (error) return <p style={{ color: 'red', textAlign: 'center' }}>Error: {error.message}</p>;

  // Стили для иконок
  const iconStyle = {
    fontSize: '16px',
    color: '#8c8c8c', // Серый цвет по умолчанию
    transition: 'color 0.3s',
    cursor: 'pointer',
  };

  return (
    <div style={{ padding: '30px' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>Список курсов</h1>
      <Row gutter={[16, 16]}>
        {data.courses.map((course) => (
          <Col span={8} key={course.id}>
            <Card
              hoverable
              bordered={false}
              style={{ borderRadius: '10px' }}
              actions={[
                <Link to={`${course.id}`} key="info">
                  <InfoCircleOutlined
                    style={iconStyle}
                    onMouseEnter={(e) => (e.currentTarget.style.color = '#1890ff')} // Синий при наведении
                    onMouseLeave={(e) => (e.currentTarget.style.color = '#8c8c8c')}
                  />
                </Link>,
                <EditOutlined
                  key="edit"
                  style={iconStyle}
                  onMouseEnter={(e) => (e.currentTarget.style.color = '#1890ff')} // Синий при наведении
                  onMouseLeave={(e) => (e.currentTarget.style.color = '#8c8c8c')}
                  onClick={() => handleEdit(course)}
                />,
                <DeleteOutlined
                  key="delete"
                  style={iconStyle}
                  onMouseEnter={(e) => (e.currentTarget.style.color = '#ff4d4f')} // Красный при наведении
                  onMouseLeave={(e) => (e.currentTarget.style.color = '#8c8c8c')}
                  onClick={() => handleDelete(course.id)}
                />,
              ]}
            >
              <Meta title={course.name} description={course.description} />
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title="Редактировать курс"
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Название курса" rules={[{ required: true, message: 'Введите название курса' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Описание курса">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CourseList;