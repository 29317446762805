import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_TEST_TO_MODULE } from '../../graphql/mutations';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Select, Upload, notification, Space } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const CreateTest = () => {
  const { moduleId } = useParams(); // Получаем moduleId из маршрута
  const navigate = useNavigate(); // Для редиректа
  const [form] = Form.useForm(); // Используем Ant Design Form
  const [addTest] = useMutation(ADD_TEST_TO_MODULE);

  const [questions, setQuestions] = useState([
    { text: '', options: [''], correctAnswers: [], type: 'SINGLE' },
  ]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { name, attempts, gradingScale, attachments } = values;
  
      // Преобразуем вопросы в формат, ожидаемый мутацией
      const formattedQuestions = questions.map((q) => ({
        text: q.text,
        options: q.options,
        correctAnswers: q.correctAnswers,
        type: q.type,
      }));
  
      // Логирование данных перед вызовом мутации
      console.log('Data being sent to the server:', {
        moduleId,
        name,
        attempts,
        gradingScale,
        questions: formattedQuestions,
      });
  
      await addTest({
        variables: {
          moduleId,
          name,
          attempts,
          gradingScale,
          questions: formattedQuestions,
        },
      });
  
      // Уведомление об успешном добавлении
      notification.success({
        message: 'Успешно',
        description: 'Тест успешно добавлен!',
      });
  
      // Редирект обратно на страницу модуля
      navigate(`/dashboard/courses/${moduleId}`);
    } catch (error) {
      console.error(error);
  
      // Уведомление об ошибке
      notification.error({
        message: 'Ошибка',
        description: 'Произошла ошибка при добавлении теста.',
      });
    }
  };

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      { text: '', options: [''], correctAnswers: [], type: 'SINGLE' },
    ]);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...questions];
    newQuestions[index][field] = value;
    setQuestions(newQuestions);
  };

  const handleAddOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push(''); // Добавляем пустой вариант ответа
    setQuestions(newQuestions);
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1); // Удаляем вариант ответа
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
  };

  const handleCorrectAnswerChange = (questionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].correctAnswers = value;
    setQuestions(newQuestions);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Создать новый тест</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Название теста"
          name="name"
          rules={[{ required: true, message: 'Пожалуйста, введите название теста!' }]}
        >
          <Input placeholder="Введите название теста" />
        </Form.Item>

        <Form.Item
          label="Количество попыток"
          name="attempts"
          initialValue={1}
          rules={[{ required: true, message: 'Пожалуйста, укажите количество попыток!' }]}
        >
          <Input type="number" placeholder="Введите количество попыток" />
        </Form.Item>

        <Form.Item
          label="Шкала оценок"
          name="gradingScale"
          rules={[{ required: true, message: 'Пожалуйста, укажите шкалу оценок!' }]}
        >
          <Input placeholder="Введите шкалу оценок" />
        </Form.Item>

        <Form.Item label="Файлы">
          <Upload>
            <Button icon={<UploadOutlined />}>Загрузить файлы</Button>
          </Upload>
        </Form.Item>

        <h3>Вопросы</h3>
        {questions.map((question, index) => (
          <div key={index} style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px' }}>
            <Form.Item
              label={`Вопрос ${index + 1}`}
              rules={[{ required: true, message: 'Пожалуйста, введите текст вопроса!' }]}
            >
              <TextArea
                placeholder="Введите текст вопроса"
                value={question.text}
                onChange={(e) => handleQuestionChange(index, 'text', e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Тип вопроса">
              <Select
                value={question.type}
                onChange={(value) => handleQuestionChange(index, 'type', value)}
              >
                <Option value="SINGLE">Один правильный ответ</Option>
                <Option value="MULTIPLE">Несколько правильных ответов</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Варианты ответов">
              {question.options.map((option, optionIndex) => (
                <div key={optionIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <Input
                    placeholder={`Вариант ${optionIndex + 1}`}
                    value={option}
                    onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                  />
                  <Button
                    danger
                    type="link"
                    onClick={() => handleRemoveOption(index, optionIndex)}
                    style={{ marginLeft: '10px' }}
                  >
                    Удалить
                  </Button>
                </div>
              ))}
              <Button type="dashed" onClick={() => handleAddOption(index)} icon={<PlusOutlined />}>
                Добавить вариант
              </Button>
            </Form.Item>

            <Form.Item label="Правильные ответы">
              <Select
                mode="multiple"
                placeholder="Выберите правильные ответы"
                value={question.correctAnswers}
                onChange={(value) => handleCorrectAnswerChange(index, value)}
              >
                {question.options.map((option, optionIndex) => (
                  <Option key={optionIndex} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Button danger onClick={() => handleRemoveQuestion(index)}>
              Удалить вопрос
            </Button>
          </div>
        ))}

        <Button type="dashed" onClick={handleAddQuestion} icon={<PlusOutlined />}>
          Добавить вопрос
        </Button>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать тест
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateTest;